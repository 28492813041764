import styles from "./styles.module.scss"
import Link from "next/link"
import { useRouter } from "next/router"
import { ModalLogin } from "@/bet-nextv1/ModalLogin"

import { useSubCore } from "@/hooks/useSubCore"
import { RxHamburgerMenu } from "react-icons/rx"
import { useCore } from "@/core-nextv3/core/core"
import { AuthContext } from "@/bet-nextv1/context/AuthContext"
import logo from "../../../public/assets/img/logo.png"
import { useContext, useEffect, useRef, useState } from "react"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { PopupsModal } from "@/bet-nextv1/PopupsModal"
import { NotificationPopup } from "@/bet-nextv1/NotificationPopup"
import { useTranslation } from "next-i18next"
import { CiClock1, CiLogin } from "react-icons/ci"
import { FaUser, FaWonSign } from "react-icons/fa"
import { QuerySnapshot, collection, onSnapshot, query, where } from "firebase/firestore"
import { FirebaseContext } from "@/bet-nextv1/context/FirebaseContext"
import dynamic from "next/dynamic"
import { getAccid } from "@/core-nextv3/util/util"
import { HeaderDesktop } from "./HeaderDesktop"
import { MenuBottom } from "@/bet-nextv1/MenuBottom"
import { HeaderMobile } from "./HeaderMobile"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import { FaPesetaSign } from "react-icons/fa6"
import { PiCurrencyEur } from "react-icons/pi";
import { FiRefreshCw } from "react-icons/fi"
import { utcToZonedTime } from "date-fns-tz"
import Types from "@/core-nextv3/type"
import { addPointsTransfer } from "@/core-nextv3/bet/bet.api"
import { TRANSFER_SETTING } from "@/setting/setting"
import { CircularProgress } from "@mui/material"


export const HeaderThree: React.FC<any> = ({
    fixtures,
    sports,
    locations,
    banks,
    dividend,
    account,
    homePage,
}:any) => 
{

   
    const router                            = useRouter()
    const pagePath                          = useRouter().asPath
    const [ openSubMenu, setOpneSubMenu ]   = useState(false)
    const [ disableClose, setDisableClose ] = useState(false)
    const [ popupsToShow, setPopupsToShow ] = useState<any>([])
    const [ openPopups, setOpenPopups ]     = useState(false)
    const { isDesktop }                     = useResponsive()
    

    const [ messageCounterAuto, setMessageCounterAuto ]         = useState<any>(0)
    const [ messageCounterAnswered, setMessageCounterAnswered ] = useState<any>(0)
    const [ otherMessagesCounter, setOtherMessagesCounter ]     = useState<any>(0)

    const firebaseApp = useContext(FirebaseContext)

    const clickRef: any = useRef(null)

    const { awaitLoadingHTTP, isLoadingUser  } = useCore()
    const { setSelectedSport }                 = useSubCore()

    const Clock = dynamic(() => import("../../bet-nextv1/clock"), { ssr : false });

    const { user, logout, setIsLogged, loading } = useContext(AuthContext)

    const { t } = useTranslation()

    const {
        setGames,
        setSports,
        setLocations,
        openLoginModal,
        setOpenLoginModal, 
        setTypeLogin,
        setReloadGames,
        setSelectedLocation,
        setDividends,
        popups,
    } = useSubCore()

    const { setAwaitLoadingHTTP }                                                                                               = useCore()
    const { setSelectedBet, setSelectedIndex, setOpenAdditionalOptions, setOpenMessageModal, setMessage, setMessageModalIcon, } =
    useSubCore()
    
    
    const { setSelectedLeague } = useSubCore()
    useEffect(() => 
    {
        setDividends(dividend)
    }, [ dividend ])

    useEffect(() => 
    {
        if (fixtures?.length > 0) 
        {
            setGames(fixtures)
        }

        if (sports?.length > 0) 
        {
            setSports(sports)
        }

        if (locations?.length > 0) 
        {
            setLocations(locations)
        }
    }, [ fixtures, sports, locations ])

    const checkMessages = (messages: any) => 
    {
        let count = 0

        for (let i = 0; i < messages?.length; i++) 
        {
            if (
                messages[i]?.views === 0 &&
        messages[i]?.answer &&
        messages[i]?.answer !== ""
            ) 
            {
                count += 1
            }
        }

        return count
    }

    const snapShotMessageOther = async (appid:string) => 
    {
        const whereCollection = [
            where("client", "==", user?.docRef),
            where("messageViewed", "==", true),
            where("answerViewed", "==", false),
            where("_archive", "==", false),
        ]

        const queryMessageOther = query(
            collection(firebaseApp.firestore, `/${getAccid()}/${appid}/documents`),
            ...whereCollection
        )

        console.error("queryMessageOther", queryMessageOther)

        const unsubscribe = onSnapshot(queryMessageOther, async (querySnapshot) => 
        {
            console.error("uuhul", querySnapshot.docs)
            const docs    = querySnapshot.docs.map((doc: any) => doc.data())
            const counter = docs?.length
            setOtherMessagesCounter(counter)
        })

        return () => unsubscribe()
    }

    const snapShotMessageMain = async () => 
    {  

        const whereCollection = [
            where("client", "==", user?.docRef),
            where("views", "==", 0),
            where("_archive", "==", false),
        ]
        const querySport      = query(
            collection(firebaseApp.firestore, `/${getAccid()}/message/documents`),
            ...whereCollection
        )

        const unsubscribe = onSnapshot(querySport, async (querySnapshot: any) => 
        {
            const docs              = querySnapshot.docs.map((doc: any) => doc.data())
            const newCounterPerView = checkMessages(docs)

            setMessageCounterAuto(newCounterPerView)
        })

        const whereCollection2 = [
            where("client", "==", user?.docRef),
            where("messageViewed", "==", true),
            where("answerViewed", "==", false),
            where("_archive", "==", false),
        ]
        const querySport2      = query(
            collection(firebaseApp.firestore, `/${getAccid()}/message/documents`),
            ...whereCollection2
        )

        const unsubscribe2 = onSnapshot(
            querySport2,
            async (querySnapshot: any) => 
            {
                const docs = querySnapshot.docs.map((doc: any) => doc.data())
                setMessageCounterAnswered(docs.length)
            }
        )

        return () => 
        {
            unsubscribe()
            unsubscribe2()
        }
    }

  

    useEffect(() => 
    {

        let result: any;

        const fetchMessages = async () => 
        {
            if (user) 
            {
                result = await snapShotMessageMain();
            }
        }
    
        fetchMessages();

        return () =>  
        {
           
            if (result)
            {
                result()
            }
        }
        // if (user) 
        // {
        //     result = snapShotMessageMain()
        //     // snapShotMessageOther("messageTwo")
        // }

    }, [ user, router.pathname ])


    useEffect(() => 
    {

        if (router.pathname === "/" && !user) 
        {
            const memo = []

            for (let i = 0; i < popups?.length; i++) 
            {
                if (popups[i]?.showBeforeLogin === true && popups[i]?.active === true) 
                {
                    memo.push(popups[i])
                }
            }

            setPopupsToShow(memo)
            setOpenPopups(true)
        }
        else if (router.pathname === "/" && user) 
        {
            const memo = []

            for (let i = 0; i < popups?.length; i++) 
            {
                if (
                    popups[i]?.showBeforeLogin === false &&
          popups[i]?.active === true
                ) 
                {
                    memo.push(popups[i])
                }
            }

            setPopupsToShow(memo)
            setOpenPopups(true)
        }
    }, [ user, popups ])

    useEffect(() => 
    {
        const handleOutSideClick = (event: any) => 
        {
            if (!clickRef?.current?.contains(event.target)) 
            {
                setOpneSubMenu(false)
            }
        }

        window.addEventListener("mousedown", handleOutSideClick)

        return () => 
        {
            window.removeEventListener("mousedown", handleOutSideClick)
        }
    }, [ clickRef ])

    const handleLogoutClick = async () => 
    {
        setAwaitLoadingHTTP(true)
        setIsLogged(false)
        await logout()
        setSelectedBet(null)
        setSelectedIndex(null)
        setOpenAdditionalOptions(false)
        setAwaitLoadingHTTP(false)
    }

    const handleSportsClick = () => 
    {
        setSelectedLeague(null)
        setSelectedSport(null)
        setSelectedLocation(null)
        setReloadGames(true)
        redirectTo("/sports/", user, router, setOpenLoginModal)
    }

    const checkNotifications = () => 
    {
        const userCheck: any = user
        const route: any     = router.asPath

        // console.log(userCheck?.views_advicesUsers, messageCounterAuto + messageCounterAnswered, otherMessagesCounter)

        if (
            route === "/deposit/?tabIndex=4" || 
            route === "/deposit/?tabIndex=3" ||
            route === "/deposit/?tabIndex=8"
        ) 
        {
            return false
        }
        else 
        {
            if (userCheck?.views_advicesUsers > 0) 
            {
                console.debug("views_advicesUsers")
                return true
            }
            else if (messageCounterAuto + messageCounterAnswered > 0) 
            {
                console.debug("messageCounter")
                return true
            }
            else if (otherMessagesCounter > 0)
            {
                console.debug("otherMessageCounter")
                return true
            }

            return false
        }
    }

    const transferPoints = async () =>
    {

        if ((user as any)?.points > 0)
        {
            setAwaitLoadingHTTP(true)
            const date      = utcToZonedTime(new Date(), "Asia/Seoul")
            const data: any = {
                client      : { referencePath : user?.referencePath },
                applyTime   : date.toISOString(),
                destination : Types.SPORT_WALLET_DESTINATION,
                type        : Types.POINTS_TRANSFER_POINTS_REWARDS,
                situation   : { id : "oOxKtAzG", label : "Aprovado", value : "approved" },
                _display    : true,
            }
    
            const result = await addPointsTransfer(TRANSFER_SETTING.merge({ data : data }))

            if (result?.status)
            {
                setMessageModalIcon("success")
                
                const message = t("Tranferencia Efetuada")
    
                setMessage(message)
                setOpenMessageModal(true)
                setAwaitLoadingHTTP(false)
                return
            }
            else 
            {
                setMessageModalIcon("warning")
                const message = t("Por-favor tente novamente mais tarde")
                setMessage(message)
                setOpenMessageModal(true)
                setAwaitLoadingHTTP(false)
                return
            }
        }
    }

    return (
        <>
            <header className={styles.header}>
                {isDesktop ?
                    <HeaderDesktop
                        account={account}
                        setTypeLogin={setTypeLogin}
                        setDisableClose={setDisableClose}
                        handleLogoutClick={handleLogoutClick}
                        setOpenLoginModal={setOpenLoginModal}
                        transferPoints={transferPoints}
                    />
                    :
                    <HeaderMobile
                        account={account}
                        homePage={homePage}
                        setTypeLogin={setTypeLogin}
                        setDisableClose={setDisableClose}
                        handleLogoutClick={handleLogoutClick}
                        setOpenLoginModal={setOpenLoginModal}
                        transferPoints={transferPoints}
                    />}
                {awaitLoadingHTTP === false && user ?  <div className={styles.balance}>
                    {/* <FaWonSign /> */}
                    <div className={styles.balanceContainer}>
                        보유금액
                        <span>{user?.sport
                            ? new Intl.NumberFormat("en").format(user?.sport)
                            : 0}{" "}</span>
                    </div>
                    {/* <FaPesetaSign /> */}
                    <div  className={styles.balanceContainer} onClick={() => transferPoints()}>
                        포인트
                        <span style={{ marginLeft : "0.8rem" }}>{(user as any)?.points
                            ? new Intl.NumberFormat("en").format((user as any)?.points)
                            : 0}{" "}</span></div>
                    {/* <PiCurrencyEur /> */}
                    <div className={styles.balanceContainer}>
                        카지노머니
                        <span>{user?.casino
                            ? new Intl.NumberFormat("en").format(user?.casino)
                            : 0}{" "}</span>
                    </div>
                    <div className={styles.subMenuGridItemButton}>
                        
                        <Link
                            className={styles.title}
                            href='/deposit/?tabIndex=2'
                            // onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}
                        >
                            {/* <img src='/assets/img/event_yellow.png' alt='' /> */}
                            {t("Transferencias")}
                        </Link>
                    </div>

                    {/* <Link className={styles.link} href='/deposit/?tabIndex=2'>
                        <FiRefreshCw />
                        <span>{`[ ${t("Transferir")}]`}</span>
                    </Link> */}
                </div> : !loading && !awaitLoadingHTTP &&
                    <div className={styles.btns}>
                        <button

                            className={styles.buttonNavigation}
                            data-bs-toggle='modal'
                            data-bs-target='#signupin'
                            onClick={() => (
                                setOpenLoginModal(true),
                                setTypeLogin("login"),
                                setDisableClose(false),
                                setOpneSubMenu(false)
                            )}
                        >
                            <span>{t("Logar")}</span>
                        </button>

                        <button

                            className={styles.buttonNavigationTow}
                            data-bs-toggle='modal'
                            data-bs-target='#signupin'
                            onClick={() => (
                                setOpenLoginModal(true),
                                setTypeLogin("register"),
                                setDisableClose(false),
                                setOpneSubMenu(false)
                            )}
                        >
                            <span className='rela'>{t("Cadastrar")}</span>
                        </button>
                    </div>
                }
                
                
            </header>

            {openLoginModal && (
                <ModalLogin
                    closeModal={setOpenLoginModal}

                    banks={banks}
                    disableClose={disableClose}
                    setDisableClose={setDisableClose}

                />
            )}

            {openPopups && (
                <PopupsModal
                    open={openPopups}
                    setOpen={setOpenPopups}
                    popups={popupsToShow}
                />
            )}

            {!openPopups && user && checkNotifications() && (
                <NotificationPopup
                    countMessages={messageCounterAuto + messageCounterAnswered}
                    countMessagesOthers={otherMessagesCounter}
                    notifications={user?.views_advicesUsers}
                />
            )}
        </>
    )
}
